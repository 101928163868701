import { configureStore } from '@reduxjs/toolkit';
import courseReducer from './courseSlice'; // Import your course slice

const store = configureStore({
  reducer: {
    course: courseReducer, // Adding the course reducer
  },
});

export default store;
