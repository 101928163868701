import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom'; // For accessing URL params
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import  courses  from './DATAS'; // Assuming the courses data is imported
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles } from '../../reduxtoolkit/courseSlice';
const SubCourses = () => {
  const { level } = useParams(); // Get the course level from the URL
  const [localStyles, setLocalStyles] = useState({
    backgroundColor: '#f8f9fa', // Default background color
    fontColor: '#000000', // Default font color
  });
  const dispatch =useDispatch();
  // Fetch styles from Redux store
  const { styles } = useSelector((state) => state.course || {});

  // Dispatch fetchStyles when the component mounts
  useEffect(() => {
    dispatch(fetchStyles());
  }, [dispatch]);

  // Default values if styles are not yet fetched
  const backgroundColor = styles && styles[0]?.pages?.backgroundColor ? styles[0]?.pages?.backgroundColor : localStyles.backgroundColor;
  const fontColor = styles && styles[0]?.pages?.fontColor ? styles[0]?.pages?.fontColor : localStyles.fontColor;

  const courseCategory = courses.find((course) => course.level === level);
  const handleContactNow = (text1, text2) => {
    const message = `I'm interested in the ${text1}, ${text2}. Please give me more details.`;
    const encodedMessage = encodeURIComponent(message);
    const phoneNumber = '+918606378283'; // Replace with your WhatsApp number
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };
  if (!courseCategory) {
    return <div>Course not found</div>; // If no matching course is found
  }

  return (
    <Container fluid className="p-4" style={{backgroundColor:backgroundColor,color:fontColor}}>
      <h2>{courseCategory.level} Sub-Courses</h2>
      <Row>
        {courseCategory.courses.map((course, index) => (
          <Col key={index} xs={12} sm={6} lg={4} className="mb-4">
            <Card style={{backgroundColor:backgroundColor,color:fontColor}}>
              <Card.Img
                variant="top"
                src={course.courseImage}
                alt={course.name}
                style={{ height: '200px', objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title>{course.name}</Card.Title>
                <Card.Text>{course.description}</Card.Text>
                <Button variant="primary" onClick={() => handleContactNow(courseCategory.level,course.name)}>
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SubCourses;
