import React ,{useEffect}from 'react';
import { Container, Card } from 'react-bootstrap';
import { Typography, Box, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles } from '../../reduxtoolkit/courseSlice';
const reviews = [
  {
    name: 'Aswathi Biju',
    rating: 5,
    time: '2 weeks ago',
    text: "Being a student of Holy Institution it's my utmost pleasure to able to study here. Excellent faculties and teaching. Good atmosphere. Best place to learn German language, teachers are so friendly... 😍",
  },
  {
    name: 'Ashna Joy',
    rating: 5,
    time: '5 months ago',
    text: "Just amazing in its every corners.👏 .......comfortable and friendly tutors....nice environment.....any time approachable centre.....more over affordable in every sense....Thank you so much Holy Institution....Best wishes .....",
  },
  {
    name: 'Alwin Joseph Babu',
    rating: 5,
    time: '5 months ago',
    text: "This institute is an apt for those who prefer to study the language especially this institution is specialized in German language, moreover highly trained teachers are instituted and provide study material too. I prefer and recommend this particular institute.",
  },
];

const Reviews = () => {
  const dispatch = useDispatch();

  // Fetch styles from Redux store
  const { styles } = useSelector((state) => state.course || {});

  // Dispatch fetchStyles when the component mounts
  useEffect(() => {
    dispatch(fetchStyles());
  }, [dispatch]);
  const backgroundColor = styles && styles[0]?.pages?.backgroundColor ? styles[0]?.pages?.backgroundColor : '#f8f9fa';
  const fontColor = styles && styles[0]?.pages?.fontColor ? styles[0]?.pages?.fontColor : '#000000';

  return (
    <Container fluid id='Reviews' className="p-4" style={{backgroundColor:backgroundColor,color:fontColor}}>
      <Box mb={4}>
        <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#C8A272',fontFamily: 'Roboto Slab, serif' }}>
          Student Reviews
        </Typography>
        <Grid container spacing={4}>
          {reviews.map((review, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card className='cardhover p-3'>
                <Card.Body>
                  <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'bold',fontFamily: 'Roboto Slab, serif' }}>
                    {review.name}
                  </Typography>
                  <Typography variant="body2" style={{fontFamily: 'Roboto Slab, serif'}} color="textSecondary">
                    {review.time}
                  </Typography>
                  <Typography variant="body1" style={{fontFamily: 'Roboto Slab, serif'}} sx={{ mt: 2 }}>
                    {review.text}
                  </Typography>
                </Card.Body>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box textAlign="center" mt={4}>
          <Button variant="contained" style={{backgroundColor:'#222222',color:'#C8A272',fontFamily: 'Roboto Slab, serif'}}  href="https://maps.app.goo.gl/VHadjai17LuxuLx97" target="_blank">
            More Reviews
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Reviews;
