import React from "react";
import "./LoadingAnimation.css";

const LoadingAnimation = ({ loading }) => {
  const message = "SGACADEMY";

  if (!loading) return null;

  return (
    <div className="loading-container">
      <h2 className="loading-text">
        {message.split("").map((char, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.3}s` }}>
            {char}
          </span>
        ))}
      </h2>
    </div>
  );
};

export default LoadingAnimation;
