import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import {
    Button,
    TextField,
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
} from "@mui/material";

const EditUniversity = ({ data }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [universities, setUniversities] = useState({
        data: data?.universities?.data || [],
        image: data?.universities?.image || "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleNameChange = (index, newName) => {
        const updatedNames = [...universities.data];
        updatedNames[index] = newName;
        setUniversities((prev) => ({
            ...prev,
            data: updatedNames,
        }));
    };

    const handleAddName = () => {
        setUniversities((prev) => ({
            ...prev,
            data: [...prev.data, ""],
        }));
    };

    const handleRemoveName = (index) => {
        const updatedNames = universities.data.filter((_, i) => i !== index);
        setUniversities((prev) => ({
            ...prev,
            data: updatedNames,
        }));
    };

    const handleImageChange = (e) => {
        setUniversities((prev) => ({
            ...prev,
            image: e.target.value,
        }));
    };



    const handleSave = async (e) => {
        e.preventDefault();

        // Validation checks
        if (universities.data.some((name) => name.trim() === "")) {
            swal("Error", "University names cannot be empty", "error");
            return;
        }

        if (!universities.image.trim()) {
            swal("Error", "Image URL cannot be empty", "error");
            return;
        }

        if (!universities.description.trim()) {
            swal("Error", "Description cannot be empty", "error");
            return;
        }

        if (!data?._id) {
            swal("Error", "Data ID is missing", "error");
            return;
        }

        setIsLoading(true);

        try {
            // Debug: Log the payload being sent to the backend
            console.log("Sending updated data to backend:", universities);

            // Sending the PUT request with updated university details
            const response = await axios.put(
                `https://sgacademyserver.onrender.com/api/data/${data._id}`,
                { universities }
            );

            console.log("Backend response:", response.data);

            // On success, show the success message and stop editing
            swal("Success", "University details updated successfully!", "success");
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating university details:", error?.response?.data || error);
            swal("Error", "Failed to update university details", "error");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ maxWidth: "800px", margin: "0 auto", padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Edit University Details
            </Typography>

            {isEditing ? (
                <form onSubmit={handleSave}>
                    <Paper sx={{ padding: 3, marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Universities
                        </Typography>
                        <List>
                            {universities.data.map((name, index) => (
                                <ListItem key={index} sx={{ display: "flex", alignItems: "center" }}>
                                    <TextField
                                        label={`University ${index + 1}`}
                                        value={name}
                                        fullWidth
                                        onChange={(e) => handleNameChange(index, e.target.value)}
                                    />
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleRemoveName(index)}
                                        sx={{ marginLeft: 2 }}
                                    >
                                        Remove
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                        <Button
                            variant="contained"
                            onClick={handleAddName}
                            sx={{ marginTop: 2 }}
                        >
                            Add University
                        </Button>
                    </Paper>

                    <Paper sx={{ padding: 3, marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Image URL
                        </Typography>
                        <TextField
                            label="Image URL"
                            fullWidth
                            value={universities.image}
                            onChange={handleImageChange}
                        />
                    </Paper>

                    

                    <Button
                        variant="contained"
                        type="submit"
                        sx={{ marginTop: 2 }}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : "Save"}
                    </Button>
                </form>
            ) : (
                <div>
                    <Typography variant="h6" gutterBottom>
                        Universities:
                    </Typography>
                    <List>
                        {universities.data.length > 0 ? (
                            universities.data.map((name, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={name} />
                                </ListItem>
                            ))
                        ) : (
                            <Typography>No universities available</Typography>
                        )}
                    </List>

                    <Box>
                        <Typography variant="h6">Image:</Typography>
                        {universities.image ? (
                            <img
                                src={universities.image}
                                alt="University"
                                style={{ maxWidth: "100%", maxHeight: "200px", marginTop: "10px" }}
                            />
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                No image available
                            </Typography>
                        )}
                    </Box>


                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsEditing(true)}
                        sx={{ marginTop: 2 }}
                    >
                        Edit
                    </Button>
                </div>
            )}
        </Box>
    );
};

export default EditUniversity;
