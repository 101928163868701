import { createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import axios from 'axios';

// Initial state
const initialState = {
  courses: [],
  styles: null,
  loading: false,
  error: null,
};

// Create slice
const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourses: (state, action) => {
      state.courses = action.payload;
    },
    setStyles: (state, action) => {
      state.styles = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Actions
export const { setCourses, setStyles, setLoading, setError } = courseSlice.actions;

// Thunks
export const fetchCourses = () => async (dispatch) => {
  dispatch(setLoading(true)); // Start loading
  try {
    const response = await axios.get('https://sgacademyserver.onrender.com/api/data');
    batch(() => {
      dispatch(setCourses(response.data.data)); // Set courses data
      dispatch(setLoading(false)); // End loading
    });
  } catch (error) {
    batch(() => {
      dispatch(setError(`Failed to fetch courses: ${error.message}`)); // Set error with detailed message
      dispatch(setLoading(false)); // End loading
    });
  }
};

export const fetchStyles = () => async (dispatch) => {
  try {
    const response = await axios.get('https://sgacademyserver.onrender.com/api/styles/');
    dispatch(setStyles(response.data)); // Set styles data
  } catch (error) {
    dispatch(setError(`Failed to fetch styles: ${error.message}`)); // Handle errors with more context
  }
};

export default courseSlice.reducer;
