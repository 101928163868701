import React,{useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Box, Typography, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import './About.css';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles } from '../../reduxtoolkit/courseSlice';
const About = () => {
    const dispatch = useDispatch();

  // Fetch styles from Redux store
  const { styles } = useSelector((state) => state.course || {});

  // Dispatch fetchStyles when the component mounts
  useEffect(() => {
    dispatch(fetchStyles());
  }, [dispatch]);
    const backgroundColor = styles && styles[0]?.pages?.backgroundColor ? styles[0]?.pages?.backgroundColor : '#f8f9fa';
    const fontColor = styles && styles[0]?.pages?.fontColor ? styles[0]?.pages?.fontColor : '#000000';
  
    return (
        <Container fluid elevation={3} className="p-4" style={{ fontFamily: 'Roboto Slab, serif',backgroundColor:backgroundColor,color:fontColor }}>
            <Helmet>
                <title>About SG Academy College of Distance Education</title>
                <meta name="description" content="Learn more about SG Academy College of Distance Education. We offer various undergraduate, postgraduate, diploma, and professional courses." />
                <meta name="keywords" content="SG Academy College, Distance Education, Degree Courses, Postgraduate Courses, Diploma Courses" />
            </Helmet>

            <Box component={Paper} elevation={3} className="p-4" sx={{ borderRadius: '15px', backgroundColor: '#222222' }}>
                <Typography variant="h3" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#C8A272', fontFamily: 'Roboto Slab, serif' }}>
                    SG Academy College of Distance Education
                </Typography>
                <Typography variant="body1" align="center" paragraph sx={{ color: '#ffffff', fontFamily: 'Roboto Slab, serif' }}>
                    SG Academy College of Distance Education offers a variety of undergraduate, postgraduate, and diploma courses with flexible timings. We provide quality education with accredited programs in fields such as Arts, Commerce, Science, and more. Our institution focuses on providing accessible education to students from diverse backgrounds through online and offline learning.
                </Typography>
                <Typography variant="body1" align="center" sx={{ color: '#ffffff', fontFamily: 'Roboto Slab, serif' }}>
                    Our services include SSLC/PLUS TWO, Degree/PG, Diploma, and PG Diploma courses approved by Kerala-Central Governments, UGC, UPSC, DEB, Kerala PSC, and more. Explore our programs and join the SG Academy College to take your education to the next level.
                </Typography>

                <Typography variant="h5" gutterBottom align="center" className="mt-5 " sx={{ fontWeight: 'bold',color: '#C8A272', fontFamily: 'Roboto Slab, serif' }}>
                    Contact Information
                </Typography>
                <Row className="justify-content-center">
                    <Col xs={12} md={4} className="text-center">
                        <PhoneIcon sx={{ fontSize: 40, color: '#2980b9' }} />
                        <Typography variant="h6" sx={{ color: '#34495e', fontWeight: 'bold', fontFamily: 'Roboto Slab, serif' }}>
                            Phone
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#2980b9', fontFamily: 'Roboto Slab, serif' }}>
                            +91 7505252695
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#2980b9', fontFamily: 'Roboto Slab, serif' }}>
                            +91 8606378283
                        </Typography>
                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <LocationOnIcon sx={{ fontSize: 40, color: '#2980b9' }} />
                        <Typography variant="h6" sx={{ color: '#34495e', fontWeight: 'bold', fontFamily: 'Roboto Slab, serif' }}>
                            Address
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#2980b9', fontFamily: 'Roboto Slab, serif' }}>
                            Near Federal Bank, Bus Stand, Kongad, Palakkad Dt., Kerala State, PIN: 678 631.
                        </Typography>
                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <EmailIcon sx={{ fontSize: 40, color: '#2980b9' }} />
                        <Typography variant="h6" sx={{ color: '#34495e', fontWeight: 'bold', fontFamily: 'Roboto Slab, serif' }}>
                            Email
                        </Typography>
                        <Typography variant="body1">
                            <a href="mailto:satheeshgopal1983@gmail.com" style={{ textDecoration: 'none', color: '#2980b9', fontFamily: 'Roboto Slab, serif' }}>
                                satheeshgopal1983@gmail.com
                            </a>
                        </Typography>
                    </Col>
                </Row>
            </Box>

            <Box component={Paper} elevation={3} className="p-4 mt-5" sx={{ borderRadius: '15px', backgroundColor: '#EFE6E1' }}>
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold', fontFamily: 'Roboto Slab, serif' }}>
                    Our Services
                </Typography>
                <Typography variant="body1" align="center" paragraph sx={{ color: '#222222', fontFamily: 'Roboto Slab, serif' }}>
                    - SSLC/PLUS TWO (STATE/NIOS), DEGREE/PG, DIPLOMA, PG DIPLOMA COURSES.
                </Typography>
                <Typography variant="body1" align="center" sx={{ color: '#222222', fontFamily: 'Roboto Slab, serif' }}>
                    - KERALA-CENTRAL GOVTs, UGC, UPSC, DEB, KERALA PSC APPROVED.
                </Typography>
            </Box>

        </Container>
    );
};

export default About;
