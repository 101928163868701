import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const EditGallery = ({ data }) => {
    const [gallery, setGallery] = useState(data?.gallery || []); // Handle undefined `data.gallery`
    const [newImageURL, setNewImageURL] = useState(''); // Input field for new image URL

    // Handle adding a new image URL to the gallery
    const handleAddImage = () => {
        if (newImageURL) {
            setGallery([...gallery, newImageURL]); // Add new URL to gallery
            setNewImageURL(''); // Clear input after adding
        } else {
            swal("Error", "Please enter a valid URL", "error");
        }
    };

    // Remove an image URL from the gallery
    const handleRemoveImage = (index) => {
        const updatedGallery = gallery.filter((_, i) => i !== index);
        setGallery(updatedGallery);
    };

    // Save the gallery updates
    const handleSave = async () => {
        if (!data?._id) {
            swal("Error", "Data ID is missing", "error");
            return;
        }

        try {
            const response = await axios.put(
                `https://sgacademyserver.onrender.com/api/data/${data._id}`,
                { gallery } // Send the gallery as a JSON body
            );

            swal("Success", "Gallery updated successfully!", "success");
            setGallery(response.data.gallery || []); // Update local gallery after successful save
        } catch (error) {
            console.error("Error updating gallery:", error);
            swal("Error", "Failed to update gallery", "error");
        }
    };

    return (
        <div className="edit-gallery text-primary">
            <h2>Gallery</h2>

            {/* Existing Images (URLs) */}
            <div className="current-gallery ">
                <center><h3 className='text-primary'>Existing Images</h3><hr className='w-25'/></center>
               <center>{gallery.length > 0 ? (
                    gallery.map((img, index) => (
                        <div key={index} className="gallery-item d-flex flex-column gap-2">
                           <center> <img
                                src={img}
                                alt={`Gallery item ${index + 1}`}
                                className="gallery-image"
                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                            /></center>
                            <center><button className='btn btn-warning w-25' onClick={() => handleRemoveImage(index)}>Remove</button></center><hr></hr>
                        </div>
                    ))
                ) : (
                    <p>No images in the gallery</p>
                )}</center> 
            </div>

            {/* Add New Image URL */}
            <div className="new-files">
                <h3>Add New Image URL</h3>
                <center><input
                    type="text"
                    className='w-50'
                    value={newImageURL}
                    onChange={(e) => setNewImageURL(e.target.value)}
                    placeholder="Enter image URL"
                    style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
                /></center>
                <button   className="btn-edit btn btn-primary w-50"
                style={{ marginTop: '5px', marginBottom: '5px' }}onClick={handleAddImage}>Add Image</button>
            </div>

            {/* Actions */}
            <div className="actions">
                <button  className="btn-edit btn btn-success w-50"
                style={{ marginTop: '5px', marginBottom: '5px' }} onClick={handleSave}>Save Changes</button>
            </div>
        </div>
    );
};

export default EditGallery;
