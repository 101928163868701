import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Button, Grid, Typography, TextField, Container } from '@mui/material';
import { CircularProgress } from '@mui/material';

const EditStyle = () => {
    const [data, setData] = useState(null);  // Initial state is null
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetching the style data on component mount
        axios.get("https://sgacademyserver.onrender.com/api/styles/")
            .then((res) => {
                // Set the fetched data to state and localStorage
                setData(res.data[0]); // Assuming the data structure is an array
                localStorage.setItem("styles", JSON.stringify(res.data[0]));
            })
            .catch((error) => {
                console.error("Error fetching styles:", error);
            });
    }, []);

    const handleEditSubmit = async () => {
        if (!data?._id) return;
        setLoading(true);
        try {
            await axios.put(`https://sgacademyserver.onrender.com/api/styles/${data._id}`, data);
            swal("Success", "Data updated successfully!", "success");
        } catch (error) {
            console.error("Error updating data:", error);
            swal("Error", "Failed to update data", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleEditChange = (f1, f2, value) => {
        setData((prev) => {
            const updatedData = {
                ...prev,
                [f1]: {
                    ...prev[f1],
                    [f2]: value,
                },
            };
            return updatedData;
        });
    };

    // Render a loading state while fetching the data
    if (data === null) {
        return (
            <Container maxWidth="md">
                <div className="text-center">
                    <CircularProgress />
                    <Typography variant="h6" gutterBottom>Loading styles...</Typography>
                </div>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <div className="mt-4">
                <Typography variant="h4" gutterBottom>Edit Style</Typography>
                <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(); }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">Navbar Background Color:</Typography>
                            <TextField
                                type="color"
                                fullWidth
                                value={data?.navbar?.backgroundColor || '#ffffff'}  // Default color if undefined
                                onChange={(e) => handleEditChange('navbar', 'backgroundColor', e.target.value)}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">Navbar Font Color:</Typography>
                            <TextField
                                type="color"
                                fullWidth
                                value={data?.navbar?.fontColor || '#000000'}  // Default color if undefined
                                onChange={(e) => handleEditChange('navbar', 'fontColor', e.target.value)}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">Pages Background Color:</Typography>
                            <TextField
                                type="color"
                                fullWidth
                                value={data?.pages?.backgroundColor || '#ffffff'}  // Default color if undefined
                                onChange={(e) => handleEditChange('pages', 'backgroundColor', e.target.value)}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">Pages Font Color:</Typography>
                            <TextField
                                type="color"
                                fullWidth
                                value={data?.pages?.fontColor || '#000000'}  // Default color if undefined
                                onChange={(e) => handleEditChange('pages', 'fontColor', e.target.value)}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                sx={{
                                    padding: "14px",
                                    fontSize: "16px",
                                }}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Update Style'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default EditStyle;
