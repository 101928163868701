import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from '../Assist/SGlogo.jpg';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './Navbar.css';


const drawerWidth = 240;
const navItems = ['Home', 'Our Courses', 'Get Admission', 'Gallery', 'About Us', 'Contact Us'];

function Navbar({ window }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [styles, setStyles] = React.useState({
    navbar: { backgroundColor: '#EFE6E1', fontColor: '#222222' },
    pages: { backgroundColor: '#418cd2', fontColor: '#b07640' },
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    fetch('https://sgacademyserver.onrender.com/api/styles/')
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setStyles(data[0]);
        }
      })
      .catch(error => console.error('Error fetching styles:', error));
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: styles.navbar.backgroundColor,
      },
      secondary: {
        main: styles.navbar.fontColor,
      },
    },
    typography: {
      fontFamily: '"Roboto Slab", serif',
    },
  });

  const renderNavItems = (item, index) => (
    <ListItem key={`${item}-${index}`} disablePadding>
      <ListItemButton
        sx={{
          textAlign: 'center',
          fontFamily: '"Roboto Slab", serif',
          color: styles.navbar.fontColor,
        }}
        onClick={() => navigate(`/${item.replace(/\s+/g, '').toLowerCase()}`)}
      >
        <ListItemText primary={item} />
      </ListItemButton>
    </ListItem>
  );

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
        backgroundColor: styles.navbar.backgroundColor,
        color: styles.navbar.fontColor,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          my: 2,
          display: { sm: 'none', xs: 'block' },
          fontFamily: 'Roboto Slab, serif',
        }}
      >
        <div className="flexs flex-column text-center align-items-center justify-content-center">
          <img src={Logo} alt="SG Academy logo" width={60} />
          <p style={{ fontSize: '10px', fontFamily: 'Roboto Slab, serif' }} className="my-2">
            SG Academy
          </p>
        </div>
      </Typography>
      <Divider />
      <List>{navItems.map(renderNavItems)}</List>
      <Footer color="white" />
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Box className="flexs" position="sticky" sx={{ marginBottom: '110px', zIndex: '2' }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar
            className="flexs justify-content-space-around"
            style={{ backgroundColor: styles.navbar.backgroundColor, color: styles.navbar.fontColor }}
          >
            <div className="w-100 m-0 flexs justify-content-space-aroud gap-5 align-items-center">
              <Typography
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className="col-7"
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <div
                  className="flexs flex-row text-center align-items-center justify-content-center"
                  style={{ marginLeft: '10px' }}
                >
                  <img src={Logo} alt="SG Academy logo" width={40} />
                  <p style={{ fontSize: '20px' }} className="my-2 mx-1">
                    SG Academy
                  </p>
                </div>
              </Typography>

              <IconButton
                color="#222222"
                aria-label="open drawer"
                edge="start"
                className="col-4"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <Typography
              variant="h6"
              className="col-3"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <div className="align-items-center my-1 justify-content-center">
                <img src={Logo} alt="SG Academy logo" width={60} />
                <p style={{ fontSize: '10px', fontFamily: 'Roboto Slab, serif' }} className="my-2">
                  SG Academy
                </p>
              </div>
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="justify-content-space-around col-9">
              {navItems.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => navigate(`/${item.replace(/\s+/g, '').toLowerCase()}`)}
                  className="mx-1 text-bold hovernavlink"
                  sx={{ color: styles.navbar.fontColor, fontFamily: 'Roboto Slab, serif' }}
                >
                  {item} <h3 className="mx-2 text-white">|</h3>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </ThemeProvider>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
