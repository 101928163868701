import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";

const EditMoreServices = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedData, setEditedData] = useState({
    description: data?.moreservices?.description || "",
  });
  const [services, setServices] = useState(data?.moreservices?.data || []);

  useEffect(() => {
    if (data?.moreservices?.data) {
      setServices(data.moreservices.data);
    }
  }, [data]);

  const handleInputChange = (field, value) => {
    setEditedData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...services];
    updatedServices[index][field] = value; // Correct field name usage
    setServices(updatedServices);
  };

  const handleAddService = () => {
    setServices((prev) => [...prev, { name: "", description: "" }]);
  };

  const handleRemoveService = (index) => {
    setServices((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!data?._id) {
      swal("Error", "Data ID is missing", "error");
      return;
    }

    // Validate all service fields
    for (const service of services) {
      if (!service.name || !service.description) {
        swal("Error", "All service fields must be filled!", "error");
        return;
      }
    }

    setLoading(true);
    try {
      await axios.put(`https://sgacademyserver.onrender.com/api/data/${data._id}`, {
        moreservices: {
          description: editedData.description,
          data: services,
        },
      });
      swal("Success", "More services updated successfully!", "success");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating more services:", error);
      swal("Error", "Failed to update services", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-moreservices text-primary">
      <h2>More Services || Description</h2>

      {isEditing ? (
        <form onSubmit={handleSave} className="d-flex flex-column gap-2">
          <div>
            <label>Description</label>
            <textarea
              value={editedData.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              required
            />
          </div>
          <div>
            <h3>Services:</h3>
            {services.map((service, index) => (
              <div key={index} className="service-item d-flex flex-column">
                <label>Name:</label>
                <input
                  type="text"
                  value={service.name}
                  onChange={(e) =>
                    handleServiceChange(index, "name", e.target.value)
                  }
                  required
                />
                <label>Description:</label>
                <textarea
                  value={service.description}
                  onChange={(e) =>
                    handleServiceChange(index, "description", e.target.value)
                  }
                  required
                />
                <center><button
                style={{ marginTop: '5px', marginBottom: '5px' }} 
                    className='btn btn-danger w-50'
                  type="button"
                  onClick={() => handleRemoveService(index)}
                >
                  Remove
                </button>
                </center>
              </div>
            ))}
          </div><hr/>
          <center><button style={{ marginTop: '5px', marginBottom: '5px' }} 
                    className='btn btn-success w-50' type="button" onClick={handleAddService}>
            Add Service
          </button></center>
          <center><button style={{ marginTop: '5px', marginBottom: '5px' }} 
                    className='btn btn-primary w-50' type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </button></center>
        </form>
      ) : (
        <div className="d-flex flex-column gap-2">
          <p>
            <strong>Description:</strong>{" "}
            {data?.moreservices?.description || "N/A"}
          </p>
          <h3>Services:</h3>
          {services.length === 0 ? (
            <p>No services available. Click "Edit" to add a service.</p>
          ) : (
            services.map((service, index) => (
              <div key={index} className="d-flex flex-column">
                <p>
                  <strong>Name:</strong> {service.name || "N/A"}
                </p>
                <p>
                  <strong>Description:</strong> {service.description || "N/A"}
                </p>
              </div>
            ))
          )}
        </div>
      )}
      <button  className="btn-edit btn btn-warning w-50"
                style={{ marginTop: '5px', marginBottom: '5px' }} onClick={() => setIsEditing((prev) => !prev)}>
        {isEditing ? "Cancel" : "Edit"}
      </button>
    </div>
  );
};

export default EditMoreServices;
