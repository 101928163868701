import React, { useEffect, useState } from 'react';
import EditContact from './EdithContacts';
import axios from 'axios';
import EditMoreServices from './moreservice';
import EditUniversity from './Edithuniverse';
import EditGallery from './EdithGallery';
import EditPG from './EdithPG';
import EditUG from './EdithUG';

const DataEditor = () => {
    const [data, setData] = useState(null);
    const [logo, setLogo] = useState('');
    const [page1, setPage1] = useState('');
    const [page2, setPage2] = useState('');

    useEffect(() => {
        // Cancel token for cleanup
        const source = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://sgacademyserver.onrender.com/api/data/',
                    { cancelToken: source.token }
                );

                console.log("API Response:", response.data); // Debug response

                const responseData = response.data[0]; // Adjust based on actual response
                if (responseData) {
                    setData(responseData);
                    setLogo(responseData?.logo || ''); // Fallback to empty if undefined
                    setPage1(responseData?.page1 || '');
                    setPage2(responseData?.page2 || '');
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log("Request canceled", err.message);
                } else {
                    console.error("Error fetching data:", err.message);
                }
            }
        };

        fetchData();

        // Cleanup function
        return () => source.cancel("Operation canceled by the user.");
    }, []);
    // Ensure Data is available before rendering
    if (!data) {
        return <div>Loading...</div>;
    }

    // Update logo image
    const handleLogoChange = (e) => {
        setLogo(e.target.value); // Update the logo URL string
    };

    // Update Page 1 image
    const handlePage1Change = (e) => {
        setPage1(e.target.value); // Update the page1 URL string
    };

    // Update Page 2 image
    const handlePage2Change = (e) => {
        setPage2(e.target.value); // Update the page2 URL string
    };

    // Save the updated images
    const handleSave = async () => {
        if (!data?._id) {
            alert("Data ID is missing");
            return;
        }
    
        console.log("Saving images:", { logo, page1, page2 }); // Debugging
    
        try {
            const response = await axios.put(
                `https://sgacademyserver.onrender.com/api/data/${data._id}/updateImages`,
                { logo, page1, page2 },
                { headers: { 'Content-Type': 'application/json' } }
            );
    
            alert("Images updated successfully!");
            setData(response.data); // Update data after save if necessary
        } catch (error) {
            console.error("Error updating images:", error);
            alert("Failed to update images");
        }
    };
    

    return (
        <div>
            <h1 className='text-success'>Data Editor</h1>< hr className='border border-dark border-3'/>
            <div>
                
                <a className='btn btn-info w-25 ' href='https://postimages.org/' >
                <h2>File To Link</h2>
                </a>
            </div>
            <div className='mx-2 p-2'><EditContact data={data} /></div>
            <hr className='border border-secondary border-3'/>
            <div className='mx-2 p-2'><EditMoreServices data={data} /></div>
            <hr className='border border-secondary border-3'/>
            <div className='mx-2 p-2'> <EditUniversity data={data} /></div>
            <hr className='border border-secondary border-3'/>
            <div className='mx-2 p-2'><EditPG data={data} /></div>
            <hr className='border border-secondary border-3'/>
            <div className='mx-2 p-2'><EditUG data={data} /></div>
            <hr className='border border-secondary border-3'/>
            <div className='mx-2 p-2'><EditGallery data={data} /></div>
            <hr className='border border-secondary border-3'/>

            {/* Logo Section */}
            <div className='text-primary'>
                <h2 className='mb-2'>Logo</h2>
                <div className='d-flex flex-column'>
                    <center><img src={logo} alt="Logo" 
                    style={{ maxWidth: '200px', maxHeight: '200px' }} />
                    </center>
                <center><input
                    type="text"
                    className="btn-edit bg-info w-25"
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    value={logo}
                    onChange={handleLogoChange}
                    placeholder="Enter new logo URL"
                /></center>
                </div>
            </div>

            {/* Page 1 Section */}
            <div>
                <h2 className='text-primary'>Page 1 Image</h2>
                <div className='d-flex flex-column'>
                    <center><img src={page1} alt="Page 1" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                    </center><center><input
                    className="btn-edit bg-info  w-25"
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    type="text"
                    value={page1}
                    onChange={handlePage1Change}
                    placeholder="Enter new Page 1 image URL"
                /></center>
                </div>
            </div>

            {/* Page 2 Section */}
            <div>
                <h2 className='text-primary'>Page 2 Image</h2>
                <div className='d-flex flex-column'>
                    <center><img src={page2} alt="Page 2" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                     </center><center> <input
                     className="btn-edit bg-info  w-25"
                     style={{ marginTop: '5px', marginBottom: '5px' }}
                    type="text"
                    value={page2}
                    onChange={handlePage2Change}
                    placeholder="Enter new Page 2 image URL"
                /></center>
                </div>
            </div>

            {/* Save Changes */}
            <div>
                <button className="btn-edit btn btn-primary  w-50"
                    style={{ marginTop: '5px', marginBottom: '5px' }} onClick={handleSave}>Save Changes</button>
            </div>
        </div>
    );
};

export default DataEditor;
