import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap"; // Bootstrap
import { TextField, Typography, Box } from "@mui/material"; // MUI
import swal from "sweetalert";

const LoginPage = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username");
    const password = formData.get("password");

    // Dummy Credentials
    const validUser = { name: "SG", pass: "SG1983" };

    if (username === validUser.name && password === validUser.pass) {
      localStorage.setItem("myaccount", JSON.stringify(validUser));
      swal("Success", "Login successful!", "success").then(() => {
        window.location.reload(); // Reload to reflect login status
      });
    } else {
      swal("Error", "Invalid credentials! Please try again.", "error");
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100">
      <Row className="shadow-lg rounded bg-light p-4 w-100" style={{ maxWidth: "400px" }}>
        <Col>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Login
          </Typography>
          <Form onSubmit={handleSubmit}>
            {/* username Field */}
            <Box mb={2}>
              <TextField
                fullWidth
                label="username"
                name="username"
                type="string"
                variant="outlined"
                required
              />
            </Box>

            {/* Password Field */}
            <Box mb={3}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                variant="outlined"
                required
              />
            </Box>

            {/* Submit Button */}
            <Button type="submit" className="w-100" variant="primary">
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
