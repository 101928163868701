import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Typography, Link, Box } from '@mui/material';

const Footer = (color) => {
  return (
    <Box component="footer" bgcolor="dark.main" py={3}>
      <Container>
        <Row className="text-center flexs flex-column">
          <Col>
            <Typography variant="body2" color= {color?'grey':"textSecondary"} style={{fontFamily: 'Roboto Slab, serif'}} gutterBottom>
              <Link href="https://www.instagram.com/holyinstitution?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                rel="noopener"
                color="inherit"
                underline="hover">
              &copy; {new Date().getFullYear()} SG Academy. All rights reserved.
              </Link>
            </Typography>
          </Col>
          <Col>
            <Typography variant="body2" color={color?'grey':"textSecondary"} style={{fontFamily: 'Roboto Slab, serif'}}>
              Created by{' '}
              <Link
                href="https://www.instagram.com/mhd_sulu_786"
                target="_blank"
                rel="noopener"
                color="inherit"
                underline="hover"
              >
                @mhd_sulu_786
              </Link>
            </Typography>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

export default Footer;
