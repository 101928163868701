import React from 'react';
import { Container } from 'react-bootstrap';

const Map = () => {
  return (
    <Container fluid className="p-3">
      <iframe
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.438837131057!2d76.5223354!3d10.854189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba87766f6884b89%3A0xa655b1d5b9db026e!2sFederal%20Bank!5e0!3m2!1sen!2sin!4v1732648089329!5m2!1sen!2sin'
        style={{ width: '100%', border: 0 }} // Responsive style
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="SG Academy Location"
      ></iframe>
    </Container>
  );
};

export default Map;
