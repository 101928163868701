import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import {
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const EditPG = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    duration: data?.pgcourses?.duration || "",
    description: data?.pgcourses?.description || "",
    availables: data?.pgcourses?.availables || [],
  });
  const [courses, setCourses] = useState(data?.pgcourses?.data || []);

  const handleChange = (field, value, isCourseField = false, courseIndex = null) => {
    if (isCourseField && courseIndex !== null) {
      const updatedCourses = [...courses];
      updatedCourses[courseIndex][field] = value;
      setCourses(updatedCourses);
    } else {
      setEditedData((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleAddCourse = () => {
    setCourses((prev) => [
      ...prev,
      { name: "", description: "", image: "" },
    ]);
  };

  const handleRemoveCourse = (index) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you cannot recover this course.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setCourses((prev) => prev.filter((_, i) => i !== index));
      }
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!editedData.duration || !editedData.description || !editedData.availables.length) {
      swal("Error", "All fields must be filled out", "error");
      return;
    }

    if (!data?._id) {
      swal("Error", "Data ID is missing", "error");
      return;
    }

    try {
      const updatedData = {
        ...editedData,
        data: courses,
      };

      const response = await axios.put(
        `https://sgacademyserver.onrender.com/api/data/${data._id}`,
        { pgcourses: updatedData }
      );

      swal("Success", "PG course updated successfully!", "success");
      setIsEditing(false);
      
      setEditedData({
        duration: response.data.pgcourses.duration,
        description: response.data.pgcourses.description,
        availables: response.data.pgcourses.availables,
      });
      setCourses(response.data.pgcourses.data);
      window.location.reload()
    } catch (error) {
      console.error("Error updating PG course:", error);
      swal("Error", "Failed to update PG course", "error");
    }
  };

  return (
    <div className="edit-pg text-primary">
      <Typography variant="h4" gutterBottom>
        PG Course Details
      </Typography>
      <center><hr  className="w-25 boder border-3 border-dark "/></center>

      {isEditing ? (
        <form onSubmit={handleSave} className="text-primary d-flex flex-column">
          <Paper sx={{ padding: 3, marginBottom: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Duration"
                  fullWidth
                  value={editedData.duration}
                  onChange={(e) => handleChange("duration", e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  value={editedData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Available Sessions"
                  fullWidth
                  value={editedData.availables.join(", ")}
                  onChange={(e) =>
                    handleChange(
                      "availables",
                      e.target.value.split(",").map((s) => s.trim())
                    )
                  }
                  placeholder="Enter sessions separated by commas"
                />
              </Grid>
            </Grid>
          </Paper>

          <Typography variant="h6" gutterBottom>
            Courses:
          </Typography>
          {courses.map((course, index) => (
            <Paper key={index} sx={{ padding: 3, marginBottom: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Course Name"
                    fullWidth
                    value={course.name}
                    onChange={(e) =>
                      handleChange("name", e.target.value, true, index)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Course Description"
                    fullWidth
                    value={course.description}
                    onChange={(e) =>
                      handleChange("description", e.target.value, true, index)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Image URL"
                    fullWidth
                    value={course.image}
                    onChange={(e) =>
                      handleChange("image", e.target.value, true, index)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveCourse(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddCourse}
            sx={{ marginBottom: 2 }}
          >
            Add Course
          </Button>

          <Button variant="contained" type="submit">
            Save
          </Button>
        </form>
      ) : (
        <div className="d-flex flex-column text-primary">
          <Typography variant="h6">Duration: {data?.pgcourses?.duration || "N/A"}</Typography>
          <Typography variant="h6">Description: {data?.pgcourses?.description || "N/A"}</Typography>
          <Typography variant="h6">
            Available Sessions: {data?.pgcourses?.availables?.join(", ") || "N/A"}
          </Typography>
          <Typography variant="h6">Courses:</Typography>
          {data?.pgcourses?.data?.map((course, index) => (
            <div key={index} className=" d-flex flex-column gap-1">
              <Typography variant="body1">
                <strong className="text-info">Course Name:</strong> {course.name || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong className="text-secondary" >Course Description:</strong> {course.description || "N/A"}
              </Typography>
              <Typography variant="body1" className="d-flex flex-column">
                <center><strong>Course Image:</strong>{" "}</center>
                <center>{course.image ? (
                  <img src={course.image} alt={course.name} style={{ maxWidth: "100px" }} />
                ) : (
                  "N/A"
                )}</center>
              </Typography>
            </div>
          ))}
        </div>
      )}

      <Button
        variant="outlined"
        onClick={() => setIsEditing((prev) => !prev)}
        sx={{ marginTop: 2 }}
      >
        {isEditing ? "Cancel" : "Edit"}
      </Button>
    </div>
  );
};

export default EditPG;
