import React,{useEffect,useState} from 'react';
import { Container, Image } from 'react-bootstrap';
import { Typography, Box, Grid } from '@mui/material';
import LazyLoad from 'react-lazyload';
import image1 from '../Assist/SG-CALLIGRAPHY.jpg';
import image2 from '../Assist/SG-FOUNDATION COURSE..jpg';
import image3 from '../Assist/SG-Tution.jpg';
import image6 from '../Assist/SGCARD.jpg';
import image7 from '../Assist/SGPoster-1.jpg';
import logo from '../Assist/SGPoster-1.jpg'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles } from '../../reduxtoolkit/courseSlice';

// Array of class base photos
const classPhotos = [
  image6,
  image1,
  image2,
  image3,
  image7,
];



const Gallery = () => {
const dispatch =useDispatch()
  const { styles } = useSelector((state) => state.course || {});
  const [localStyles, setLocalStyles] = useState({
    backgroundColor: '#f8f9fa', // Default background color
    fontColor: '#000000', // Default font color
  });
  // Dispatch fetchStyles when the component mounts
  useEffect(() => {
    dispatch(fetchStyles());
  }, [dispatch]);
  const backgroundColor = styles && styles[0]?.pages?.backgroundColor ? styles[0]?.pages?.backgroundColor : localStyles.backgroundColor;
  const fontColor = styles && styles[0]?.pages?.fontColor ? styles[0]?.pages?.fontColor : localStyles.fontColor;


  return (
    <Container fluid id='Gallery' style={{backgroundColor:backgroundColor,color:fontColor}} className="p-4">
     <Helmet>
  <title>SG Academy - Distance Education Programs</title>
  <meta name="description" content="Join SG Academy for distance education programs in a variety of fields, including undergraduate, postgraduate, diploma, and vocational courses. Start your education journey today!" />
  <meta name="keywords" content="SG Academy, Distance Education, UG courses, PG courses, Diploma courses, B.Ed, M.Ed, Kerala, Palakkad" />
  
  <meta property="og:title" content="SG Academy - College of Distance Education" />
  <meta property="og:description" content="Enroll in SG Academy's diverse distance education programs. Offering UG, PG, Diploma, and vocational courses in various fields." />
  <meta property="og:url" content="https://www.sgacademy.com" />
  <meta property="og:type" content="website" />
  <meta property="og:locale" content="en_IN" />
  <meta property="og:site_name" content="SG Academy" />
  <meta property="og:region" content="Kerala" />
  <meta property="og:location" content="Kongad, Palakkad, Kerala" />
  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="SG Academy - Distance Education Programs" />
  <meta name="twitter:description" content="Join SG Academy for distance education in undergraduate, postgraduate, diploma, and vocational courses to advance your career." />
  
  <meta property="og:image" content={logo} />
</Helmet>

      {/* Display Class Base Photos at the Top */}
      <Box mb={4}>

        <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: fontColor||'#C8A272', fontFamily: 'Roboto Slab, serif' }}>
          Gallery
        </Typography>
        <Grid container spacing={2} className="mb-4">

          {classPhotos.map((photo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {/* Wrap image with LazyLoad */}
              <LazyLoad height={200} offset={100} once>
                <Image
                  src={photo}
                  alt={`Class photo ${index + 1}`}
                  style={{ width: '100%', borderRadius: '8px', objectFit: 'cover' }}
                />
              </LazyLoad>
            </Grid>
          ))}
        </Grid>
      </Box>



    </Container>
  );
};

export default Gallery;
/*import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const courses = [
  {
    level: 'Undergraduate Courses',
    image: 'https://img.freepik.com/free-photo/academy-certification-curriculum-school-icon_53876-122521.jpg?t=st=1732645234~exp=1732648834~hmac=c9c0a697566e15d1491e318f8c112a414c1abc84fd1439ef0e1065f0447a1f0c&w=996',
    courses: [
      {
        name: 'Bachelor of Arts in English Language and Literature',
        path: '/courses/ba/english',
        courseImage: 'https://img.freepik.com/free-photo/bachelor-degree-concept_23-2147726367.jpg',
      },
      {
        name: 'Bachelor of Arts in Hindi Language and Literature',
        path: '/courses/ba/hindi',
        courseImage: 'https://img.freepik.com/free-photo/indian-courses_23-2147726367.jpg',
      },
      {
        name: 'Bachelor of Arts in Sanskrit Language and Literature',
        path: '/courses/ba/sanskrit',
        courseImage: 'https://img.freepik.com/free-photo/ancient-sanskrit-texts_23-2147726367.jpg',
      },
      {
        name: 'Bachelor of Arts in Arabic Language and Literature',
        path: '/courses/ba/arabic',
        courseImage: 'https://img.freepik.com/free-photo/arabic-education-books_23-2147726367.jpg',
      },
      {
        name: 'Bachelor of Business Administration',
        path: '/courses/ba/bba',
        courseImage: 'https://img.freepik.com/free-photo/business-administration-course_23-2147726367.jpg',
      },
      {
        name: 'Bachelor of Computer Application',
        path: '/courses/ba/bca',
        courseImage: 'https://img.freepik.com/free-photo/computer-science_23-2147726367.jpg',
      },
    ],
    description: 'Explore various undergraduate courses offered by SG Academy.',
    services: [
      'Study Materials',
      'Online Classes',
      'Free Counseling',
      'Placement Assistance',
      'Flexible Timings',
    ],
  },
  {
    level: 'Diploma & Other Programs',
    image: 'https://img.freepik.com/free-photo/woman-with-graduation-diploma_23-2148928762.jpg?t=st=1732645287~exp=1732648887~hmac=5acfc666dfcf5f782ec04d31ea5f75706078e94e3017cf0fa791356da7c7fefe&w=900',
    courses: [
      {
        name: 'Various Diploma Courses',
        path: '/courses/diploma',
        courseImage: 'https://img.freepik.com/free-photo/diploma-courses_23-2147726367.jpg',
      },
      {
        name: 'Diploma in Calligraphy (English, Hindi, Malayalam, Tamil, Telugu)',
        path: '/courses/diploma/calligraphy',
        courseImage: 'https://img.freepik.com/free-photo/calligraphy-diploma_23-2147726367.jpg',
      },
    ],
    description: 'Diploma and other specialized programs to enhance your skills.',
    services: [
      'Study Materials',
      'Online Classes',
      'Free Counseling',
      'Certification Assistance',
      'Career Guidance',
    ],
  },
  {
    level: 'Postgraduate Courses',
    image: 'https://img.freepik.com/free-photo/young-student-wearing-graduation-cape-looking-her-notebook_114579-92485.jpg?t=st=1732645325~exp=1732648925~hmac=193ea6d174df80a21a98f4c7f7330d78cf6a3babe5df205fc5c08b3830bf8832&w=996',
    courses: [
      {
        name: 'Master of Arts in English Language and Literature',
        path: '/courses/ma/english',
        courseImage: 'https://img.freepik.com/free-photo/ma-english-course_23-2147726367.jpg',
      },
      {
        name: 'Master of Arts in Malayalam Language and Literature',
        path: '/courses/ma/malayalam',
        courseImage: 'https://img.freepik.com/free-photo/ma-malayalam-course_23-2147726367.jpg',
      },
      {
        name: 'Master of Arts in Hindi Language and Literature',
        path: '/courses/ma/hindi',
        courseImage: 'https://img.freepik.com/free-photo/ma-hindi-course_23-2147726367.jpg',
      },
    ],
    description: 'Pursue advanced postgraduate courses to elevate your academic career.',
    services: [
      'Advanced Study Materials',
      'Personalized Counseling',
      'Career Development',
      'Research Guidance',
      'Exam Slot Assistance',
    ],
  },
  {
    level: 'Services',
    image: 'https://img.freepik.com/free-photo/consultation-service-concept_23-2148227569.jpg',
    courses: [
      {
        name: 'Career Counseling',
        path: '/services/career-counseling',
        courseImage: 'https://img.freepik.com/free-photo/career-counseling-service_23-2148292548.jpg',
      },
      {
        name: 'Placement Assistance',
        path: '/services/placement-assistance',
        courseImage: 'https://img.freepik.com/free-photo/job-placement-assistance_23-2147792774.jpg',
      },
      {
        name: 'Study Materials',
        path: '/services/study-materials',
        courseImage: 'https://img.freepik.com/free-photo/study-materials-service_23-2148292358.jpg',
      },
      {
        name: 'Flexible Timings',
        path: '/services/flexible-timings',
        courseImage: 'https://img.freepik.com/free-photo/flexible-timings-service_23-2148292374.jpg',
      },
      {
        name: 'Online Classes',
        path: '/services/online-classes',
        courseImage: 'https://img.freepik.com/free-photo/online-classes-service_23-2148292394.jpg',
      },
    ],
    description: 'Explore the various services we offer to enhance your learning experience.',
    services: [
      'Personalized Counseling',
      'Placement Assistance',
      'Study Materials',
      'Online Classes',
      'Career Guidance',
    ],
  },
];

const CoursePage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Helmet>
        <title>Courses - SG Academy</title>
        <meta name="description" content="Explore our courses and services at SG Academy." />
      </Helmet>

      <Container>
        <Typography variant="h3" gutterBottom align="center">
          Our Courses and Services
        </Typography>

        {courses.map((category, index) => (
          <div key={index}>
            <Box sx={{ mb: 4 }}>
              <img src={category.image} alt={category.level} style={{ width: '100%', borderRadius: '8px' }} />
              <Typography variant="h4" align="center" sx={{ mt: 2 }}>
                {category.level}
              </Typography>
              <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                {category.description}
              </Typography>
              <Grid container spacing={3}>
                {category.courses.map((course, idx) => (
                  <Grid item xs={12} md={4} key={idx}>
                    <Card>
                      <Card.Img variant="top" src={course.courseImage} />
                      <Card.Body>
                        <Card.Title>{course.name}</Card.Title>
                        <Button
                          variant="primary"
                          onClick={() => navigate(course.path)}
                        >
                          View Course
                        </Button>
                      </Card.Body>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default CoursePage;
 */
