import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Contact from './Components/contact/Contact';
import Map from './Components/Map/Map';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';
import Gallery from './Components/Gallery/Gellery';
import Courses from './Components/Coures/Courses';
import Home from './Components/Home/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Components/ScrollTop';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Reviews from './Components/Gallery/Reviews';
import { Analytics } from '@vercel/analytics/react';
import { Helmet } from 'react-helmet';
import CourseDetails from './Components/Coures/detailes';
import DataEditor from './Components/Edith/DataEditor';
import Login from './Components/Edith/Login';
import EditStyle from './EdithStyle/edithStyle';
import Subcourses from './Components/subcoures/Subcourses';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourses, fetchStyles } from './reduxtoolkit/courseSlice'; // Import actions
import SubCourses from './Components/Coures/detailes';
import LoadingAnimation from './Components/Loading/loading';

const App = () => {
  const [isUserAuthenticated, setUserAuthenticated] = useState(false);

  // Redux state
  const dispatch = useDispatch();
  const { courses, styles, loading, error } = useSelector((state) => state.course);

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchStyles());

    const storedUser = localStorage.getItem('myaccount');
    if (storedUser) {
      try {
        const user = JSON.parse(storedUser);
        if (user.name === 'SG' && user.pass === 'SG1983') {
          setUserAuthenticated(true);
        } else {
          setUserAuthenticated(false);
        }
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
        setUserAuthenticated(false);
      }
    }
  }, [dispatch]);

  // Default layout for the app
  const DefaultLayout = () => (
    <div
      className="flexs-all-col w-100"
      style={{
        backgroundColor: styles?.[0]?.pages?.backgroundColor || "white",
        color: styles?.[0]?.pages?.fontColor || "black",
        width:'100%',
        padding:"none",
        margin:'none',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      }}
    >
      <Home DATAS={courses} />
      <div className="w-50 border mb-5"></div>
      <Courses DATAS={courses} />
      <div className="w-50 border mb-5"></div>
      <Gallery DATAS={courses} />
      <div className="w-50 border mb-5"></div>
      <About DATAS={courses} />
      <div className="w-50 border mb-5"></div>
      <Reviews DATAS={courses} />
      <div className="w-50 border mb-5"></div>
      <Contact DATAS={courses} />
      <div className="w-100 border my-5 border-dark"></div>
    </div>
  );

  if (loading) {
    return     <div style={{ width: "100%", height: "50vh" }}>
                        <LoadingAnimation loading={loading}/>
              </div>;
  }

  if (error) {
    window.location.reload();
    return <div>Error: {error}</div>;
  }

  return (
    <Router>
      <ScrollToTop />
      <div
        className="App flexs flex-column bg-light"
        style={{
          backgroundColor: styles?.[0]?.pages?.backgroundColor || "white",
          color: styles?.[0]?.pages?.fontColor || "black",
        }}
      >
        <Helmet>
          <title>SG Academy - Learn now</title>
          <meta name="description" content="Open Degree, tuition, ..." />
        </Helmet>
        <Navbar DATAS={courses} />
        <div
          style={{
            position: 'fixed',
            bottom: '40px',
            right: '20px',
            zIndex: 2,
            
          }}
        >
          <a
            className="cardhover"
            href="https://wa.me/+918606378283?text=Hello%20SGCAdemy,%20I%20am%20interested%20in%20your%20courses%20and%20open%20degree.%20Please%20provide%20more%20details."
            aria-label="Contact via WhatsApp"
          >
            <WhatsAppIcon
              style={{ color: 'whitesmoke' }}
              fontSize="large"
              className="bg-success rounded-circle"
            />
          </a>
        </div>
        <Routes>
          <Route path="/" element={<DefaultLayout />} />
          <Route path="/Home" element={<DefaultLayout />} />
          <Route path="/courses/:level/:course" element={<CourseDetails DATAS={courses} />} />
          <Route path="/OurCourses" element={<><Courses DATAS={courses} /> <Reviews DATAS={courses} /></>} />
          <Route path="/Gallery" element={<><Gallery DATAS={courses} /> <Reviews /></>} />
          <Route path="/aboutus" element={<><About DATAS={courses} /> <Reviews DATAS={courses} /></>} />
          <Route path="/GetAdmission" element={<Contact DATAS={courses} />} />
          <Route path="/contactus" element={<Contact DATAS={courses} />} />
          <Route path="/map" element={<Map DATAS={courses} />} />
          <Route path="/:section" element={<Subcourses DATAS={courses} />} />
          <Route path="/courses/:level" element={<SubCourses />} />

          <Route
            path="/edith"
            element={isUserAuthenticated ? <DataEditor /> : <Login />}
          />
          <Route
            path="/style"
            element={isUserAuthenticated ? <EditStyle /> : <Login />}
          />
          <Route path="*" element={<div>404 - Page Not Found</div>} />
        </Routes>
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
};

export default App;
