import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { Typography, Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import courses from './DATAS.json'; // Assuming this imports the courses data
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles } from '../../reduxtoolkit/courseSlice';

const Courses = ({ STYLE }) => {
  const [localStyles, setLocalStyles] = useState({
    backgroundColor: '#f8f9fa', // Default background color
    fontColor: '#000000', // Default font color
  });
  const navigate = useNavigate();
  const dispatch =useDispatch();
  // Fetch styles from Redux store
  const { styles } = useSelector((state) => state.course || {});

  // Dispatch fetchStyles when the component mounts
  useEffect(() => {
    dispatch(fetchStyles());
  }, [dispatch]);

  // Default values if styles are not yet fetched
  const backgroundColor = styles && styles[0]?.pages?.backgroundColor ? styles[0]?.pages?.backgroundColor : localStyles.backgroundColor;
  const fontColor = styles && styles[0]?.pages?.fontColor ? styles[0]?.pages?.fontColor : localStyles.fontColor;

  const handleContactNow = (text1, text2) => {
    const message = `I'm interested in the ${text1}, ${text2}. Please give me more details.`;
    const encodedMessage = encodeURIComponent(message);
    const phoneNumber = '+918606378283'; // Replace with your WhatsApp number
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleLearnMore = (courseLevel) => {
    navigate(`/courses/${courseLevel}`); // Navigate to the sub-courses page
  };

  return (
    <Container fluid id="Courses" className="p-4">
      <Helmet>
        <title>SG Academy Courses</title>
        <meta
          name="description"
          content="Explore the various undergraduate, diploma, postgraduate courses, and services at SG Academy."
        />
        <meta
          name="keywords"
          content="SG Academy, undergraduate courses, postgraduate courses, diploma courses, services, online classes, counseling, study materials"
        />
      </Helmet>
      <Box mb={4} sx={{ backgroundColor: backgroundColor }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: fontColor || '#C8A272',
            fontFamily: 'Roboto Slab, serif',
          }}
        >
          Our Courses & Services
        </Typography>
        <Typography variant="body1" align="center" style={{ fontFamily: 'Roboto Slab, serif',color:fontColor }} paragraph>
          SG Academy offers a wide range of courses and services to help you achieve your academic and career goals. Explore our offerings below.
        </Typography>
        <Grid container spacing={4}>
          {courses.map((courseCategory, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card className="cardhover" style={{ height: '100%',backgroundColor:backgroundColor,color:fontColor }}>
                <Card.Body>
                  <Card.Img
                    variant="top"
                    src={courseCategory.image}
                    alt={`${courseCategory.level} image`}
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <Card.Title
                    className="text-center"
                    style={{ fontFamily: 'Roboto Slab, serif', color: fontColor || '#C8A272' }}
                  >
                    {courseCategory.level}
                  </Card.Title>
                  <Card.Text style={{ fontFamily: 'Roboto Slab, serif', textAlign: 'center' }}>
                    {courseCategory.description}
                  </Card.Text>

                  <Button
                    style={{
                      backgroundColor: fontColor||'#C8A272',
                      borderColor: '#C8A272',
                      color: backgroundColor||'#222222',
                      fontFamily: 'Roboto Slab, serif',
                      textAlign: 'center',
                    }}
                    onClick={() => handleLearnMore(courseCategory.level)} // Corrected to pass the level
                  >
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Courses;
