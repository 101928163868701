
import React, { useState ,useEffect} from 'react'; // React and hooks
import { Container } from 'react-bootstrap'; // Bootstrap Container
import Map from '../Map/Map'; // Assuming Map is a custom component in your project
import './Contact.css'; // Your custom CSS
import { Email, Facebook, Instagram, LocationOn, Phone } from '@mui/icons-material'; // MUI icons
import { Typography } from '@mui/material'; // Typography from MUI

import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles } from '../../reduxtoolkit/courseSlice';
const Contact = () => {
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setLevel] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  // Fetch styles from Redux store
  const { styles } = useSelector((state) => state.course || {});
  const backgroundColor = styles && styles[0]?.pages?.backgroundColor ? styles[0]?.pages?.backgroundColor : '#f8f9fa';
  const fontColor = styles && styles[0]?.pages?.fontColor ? styles[0]?.pages?.fontColor : '#000000';

  // Dispatch fetchStyles when the component mounts
  useEffect(() => {
    dispatch(fetchStyles());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !number || !email || !message) {
      setError('Please fill out all fields.');
      return;
    } else {
      setError('');
    }

    const whatsappMessage = `Hello SGCAdemy, 
I am ${name}. 
My contact number is ${number}, and my email ID is ${email}. 
Here is my message: ${message}. 
Please provide more details.`;

    // Encode the message to make it URL-friendly
    const encodedMessage = encodeURIComponent(whatsappMessage);

    // Redirect to WhatsApp chat
    const whatsappLink = `https://wa.me/+918606378283?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
    setName('');
    setNumber('');
    setEmail('')
    setLevel('')
  };

  return (
    <Container id='Contact' style={{ fontFamily: 'Roboto Slab, serif',backgroundColor:backgroundColor,color:fontColor }} fluid className='m-0 p-2 w-100'>
      <Container fluid className='m-0 p-2 flexs flex-wrap align-items-center justify-content-center content-container'>
        <div className="flexs flex-column bg-dark contact-info" style={{ borderRadius: '10px', borderBottomRightRadius: '0' }}>
          <Container className="contact-container flexs flex-column align-items-center p-4">
            <div className="contact-info bg-dark text-white p-4 rounded-start">
              <Typography variant="h5" className="text-center mb-3">
                <h3 className='text-white'>Contact Information</h3>
              </Typography>
              <div className="contact-details">
                <div className="flexs align-items-center mb-3">
                  <Phone className="icon me-2" />
                  <Typography variant="body1" style={{ fontFamily: 'Roboto Slab, serif' }}>+91 7505252695</Typography>
                </div>
                <div className="flexs align-items-center mb-3">
                  <Email className="icon me-2" />
                  <Typography variant="body1" style={{ fontFamily: 'Roboto Slab, serif' }}>satheeshgopal1983@gmail.com</Typography>
                </div>
                <div className="flexs align-items-center mb-3">
                  <LocationOn className="icon me-2" />
                  <Typography variant="body1" style={{ fontFamily: 'Roboto Slab, serif' }}>
                    Near Federal Bank, Bus Stand, Kongad, Palakkad Dt., Kerala State, PIN: 678 631.
                  </Typography>
                </div>
              </div>
              <Map />
            </div>
          </Container>
        </div>
        <div className="flexs flex-column text-center contact-form-container">
          <h2 className='text-dark text-center'>Contact Us</h2>
          <form onSubmit={handleSubmit} className="form flexs p-2 flex-column" style={{ border: '2px solid black', borderLeft: 'none', borderRadius: '10px', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}>
            <div className='flexs-all-col gap-3 p-2'>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='w-100 mt-5'
                placeholder='Enter Your Full Name'
                style={{ border: '1px solid black', borderRadius: '10px', height: '50px' }}
              />
              <input
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                className='w-100 my-3'
                placeholder='Enter Your Phone Number'
                style={{ border: '1px solid black', borderRadius: '10px', height: '50px' }}
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='w-100 my-3'
                placeholder='Enter Your Email ID'
                style={{ border: '1px solid black', borderRadius: '10px', height: '50px' }}
              />
              <input
                type="text"
                value={message}
                onChange={(e) => setLevel(e.target.value)}
                className='w-100'
                placeholder='Enter something...'
                style={{ border: '1px solid black', borderRadius: '10px', height: '50px' }}
              />
              {error && <p className='text-danger my-0'>{error}</p>}
              <button type="submit" className='bg-dark text-white cardhover' style={{ height: '50px', width: '120px', fontFamily: 'Roboto Slab, serif' }}>Send Now</button>
            </div>
          </form>
        </div>
      </Container>
    </Container>
  );
};

export default Contact;
