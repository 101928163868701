import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './reduxtoolkit/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Get background color from localStorage
const styles = JSON.parse(localStorage.getItem("styles"));
const backgroundColor = styles ? styles[0]?.pages?.backgroundcolor : '#ffffff';  // Default to white if not found

// Apply the background color to the body or a specific element
document.body.style.backgroundColor = backgroundColor;

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
