import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const EditContact = ({ data }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({
        name: data?.contacts?.name || '',
        address: data?.contacts?.address || '',
        email: data?.contacts?.email || '',
        phone1: data?.contacts?.phone1 || '',
        phone2: data?.contacts?.phone2 || '',
    });

    const handleInputChange = (field, value) => {
        setEditedData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSave = async (e) => {
        e.preventDefault();

        if (!data?._id) {
            swal("Error", "Data ID is missing", "error");
            return;
        }

        try {
            await axios.put(
                `https://sgacademyserver.onrender.com/api/data/${data._id}`,
                { contacts: editedData }
            );
            swal("Success", "Contact updated successfully!", "success");
            window.location.reload()
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating contact:", error);
            swal("Error", "Failed to update contact", "error");
        }
    };

    return (
        <div className="contacts text-primary" >
            <h2>Contact Details</h2>

            {isEditing ? (
                <form onSubmit={handleSave} className='d-flex flex-column gap-2'>
                    <div>
                        <label>Name:</label>
                        <input
                            type="text"
                            value={editedData.name}
                            onChange={(e) => handleInputChange('name', e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Address:</label>
                        <input
                            type="text"
                            value={editedData.address}
                            onChange={(e) => handleInputChange('address', e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={editedData.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Phone 1:</label>
                        <input
                            type="tel"
                            value={editedData.phone1}
                            onChange={(e) => handleInputChange('phone1', e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Phone 2:</label>
                        <input
                            type="tel"
                            value={editedData.phone2}
                            onChange={(e) => handleInputChange('phone2', e.target.value)}
                        />
                    </div>
                    <center ><button style={{ marginTop: '5px', marginBottom: '5px' }} 
                    className='btn btn-primary w-50'
                        type="submit">Save</button>
                        </center>
                </form>
            ) : (
                <div className='d-flex flex-column gap-2'>
                    <p><strong>Name:</strong> {data?.contacts?.name || 'N/A'}</p>
                    <p><strong>Address:</strong> {data?.contacts?.address || 'N/A'}</p>
                    <p><strong>Email:</strong> {data?.contacts?.email || 'N/A'}</p>
                    <p><strong>Phone 1:</strong> {data?.contacts?.phone1 || 'N/A'}</p>
                    <p><strong>Phone 2:</strong> {data?.contacts?.phone2 || 'N/A'}</p>
                </div>
            )}

            <button
                className="btn-edit btn btn-warning w-50"
                style={{ marginTop: '5px', marginBottom: '5px' }}
                
                onClick={() => setIsEditing((prev) => !prev)}
            >
                {isEditing ? 'Cancel' : 'Edit'}
            </button>
        </div>
    );
};

export default EditContact;
