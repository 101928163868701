import React from 'react'
import { useParams } from 'react-router-dom'

const Subcourses = ({DATAS}) => {
    const section =useParams()
  return (
    <div>Subcourses</div>
  )
}

export default Subcourses